import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { getCacheId } from '../helpers/graphql';
import { apiDomain } from '../consts/env.consts';

const uploadLink = createHttpLink({
    uri: `${apiDomain}/graphql`,
});

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ dataIdFromObject: getCacheId }),
    link: ApolloLink.from([uploadLink]),
});
