import { MAX_DOGS } from './spot.consts';

export const SF_FENCE_OPTIONS = [
    {
        value: undefined,
        label: 'Any height',
    },
    {
        value: 'FT_3',
        label: '3+ ft',
    },
    {
        value: 'FT_4',
        label: '4+ ft',
    },
    {
        value: 'FT_5',
        label: '5+ ft',
    },
    {
        value: 'FT_6_PLUS',
        label: '6+ ft',
    },
];

export const SF_SIZE_OPTIONS = [
    {
        value: 0.25,
        label: '0.25+ acres',
    },
    {
        value: 0.5,
        label: '0.5+ acres',
    },
    {
        value: 1,
        label: '1+ acre',
    },
    {
        value: 2,
        label: '2+ acres',
    },
    {
        value: 5,
        label: '5+ acres',
    },
    {
        value: 10,
        label: '10+ acres',
    },
];

export const SF_DISTANCE_OPTIONS = [
    {
        value: 0.5,
        label: 'Less than 0.5 mi',
    },
    {
        value: 1,
        label: 'Less than 1 mi',
    },
    {
        value: 5,
        label: 'Less than 5 mi',
    },
    {
        value: 10,
        label: 'Less than 10 mi',
    },
    {
        value: 20,
        label: 'Less than 20 mi',
    },
    {
        value: 50,
        label: 'Less than 50 mi',
    },
];

export const SF_AVAILABILITY_OPTIONS = [
    {
        value: null,
        label: 'Anytime',
    },
    {
        value: 'TODAY',
        label: 'Today',
    },
    {
        value: 'TOMORROW',
        label: 'Tomorrow',
    },
    {
        value: 'THISWEEKEND',
        label: 'This weekend',
    },
];

const tempMaxDogs = [];

for (let i = 0; i < MAX_DOGS; i++) {
    tempMaxDogs.push({
        value: i + 1,
        label: `${i + 1} dog${i > 0 ? 's' : ''}`,
    });
}

export const SF_MAX_DOGS_OPTIONS = [
    {
        value: undefined,
        label: 'Any',
    },
    ...tempMaxDogs,
];

export const SF_SORT_BY_OPTIONS = [
    {
        value: undefined,
        label: 'Relevance',
    },
    {
        value: 'DISTANCE',
        label: 'Distance',
    },
];
