import { M_ADD_SPOT_TO_FAVORITES, M_RM_SPOT_FROM_FAVORITES } from '../graphql/spot.mutations';
import { Q_FAVORITE_SPOTS, Q_FAVORITE_SPOTS_DETAIlS } from '../graphql/spots.queries';

export const SET_FAVORITE_SPOTS = 'SET_FAVORITE_SPOTS';
export const SET_SPOT_FAVORITE = 'SET_SPOT_FAVORITE';
export const FAVORITE_SPOTS_PENDING = 'FAVORITE_SPOTS_PENDING';
export const FILTER_FAVORITE_SPOTS = 'FILTER_FAVORITE_SPOTS';

export const toggleSpotFavorite =
    (spot) =>
    (_dispatch, getState, { apolloClient }) => {
        const { id } = spot;
        const mutationBody = { variables: { id } };
        const {
            favoriteSpots: { favorites },
        } = getState();

        const isFavorite = favorites.some((item) => item.id === id);

        if (isFavorite) {
            mutationBody.mutation = M_RM_SPOT_FROM_FAVORITES;
        } else {
            mutationBody.mutation = M_ADD_SPOT_TO_FAVORITES;
        }

        return apolloClient.mutate({ ...mutationBody });
    };

export const getFavoriteSpots =
    (filters, force = false, type = SET_FAVORITE_SPOTS, details = false) =>
    (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: FAVORITE_SPOTS_PENDING, payload: true });
        return apolloClient
            .query({
                variables: { filters },
                query: details ? Q_FAVORITE_SPOTS_DETAIlS : Q_FAVORITE_SPOTS,
                fetchPolicy: force ? 'network-only' : 'cache-first',
            })
            .then(({ data }) => {
                dispatch({
                    type,
                    payload: {
                        spots: data?.favoriteSpots,
                        filters: filters,
                    },
                });
                return data?.favoriteSpots;
            })
            .finally(() => {
                dispatch({ type: FAVORITE_SPOTS_PENDING, payload: false });
            });
    };

export const filterFavoriteSpots = (filters) => (dispatch) => dispatch(getFavoriteSpots(filters, false, FILTER_FAVORITE_SPOTS));
