// TODO Remove. spot-list.actions.js is new version.
import AppolloClient from 'apollo-boost';
import { createUploadLink } from 'apollo-upload-client';
import { gql } from '@apollo/client';
import { apiDomain, COOKIE_MODE } from '../consts/env.consts';
import { SPOT_UPDATE } from '../graphql/spot.mutations';
import { SPOT_INFO_LISTING_QUERY, SPOT_REVIEWS_DATA, SPOTS_PAGED_WITH_BOX_QUERY } from '../graphql/spots.queries';
import { QUERY_USER_DETAILS } from '../graphql/user-queries';
import { SPOT_INFO_QUERY } from '../graphql/spots.queries';
import { SPOT_GRAPHQL_ERROR } from './spot.error';
import { REVIEW_FRAGMENT } from '../graphql/review-fragments';
import { SET_USER_LOCATION } from './user.actions';
import { getSearchFiltersForServer } from '../pages/listings/utils';
import { SET_SEARCH_FILTERS_REFRESH } from './search-filters.actions';
import { setUserDetails } from './user.actions';

export const SET_SPOT_DATA = 'SET_SPOT_DATA';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const SET_SPOTS_LIST = 'SET_SPOTS_LIST';
export const SET_LOADING = 'SET_LOADING';
export const SET_SPOTS_LIST_SUCCESS = 'SET_SPOTS_LIST_SUCCESS';
export const SET_SPOTS_LIST_ERROR = 'SET_SPOTS_LIST_ERROR';
export const SET_FILTERED_SPOTS = 'SET_FILTERED_SPOTS';
export const ADD_REVIEWS_TO_LIST = 'ADD_REVIEWS_TO_LIST';
export const GET_SPOT_INFO = 'GET_SPOT_INFO';
export const GET_SPOT_INFO_SUCCESS = 'GET_SPOT_INFO_SUCCESS';
export const GET_SPOT_INFO_ERROR = 'GET_SPOT_INFO_ERROR';
export const CLEAR_DATA = 'CLEAR_DATA';
export const GET_MORE_COMMENTS = 'GET_MORE_COMMENTS';
export const GET_MORE_COMMENTS_SUCCESS = 'GET_MORE_COMMENTS_SUCCESS';
export const GET_MORE_COMMENTS_ERROR = 'GET_MORE_COMMENTS_ERROR';
export const SET_ACTIVE_LISTINGS_ITEM = 'SET_ACTIVE_LISTINGS_ITEM';
export const SET_INACTIVE_LISTINGS_ITEM = 'SET_INACTIVE_LISTINGS_ITEM';
export const SUBSCRIBE_FOR_UPDATE = 'SUBSCRIBE_FOR_UPDATE';
export const SUBSCRIBE_FOR_UPDATE_SUCCESS = 'SUBSCRIBE_FOR_UPDATE_SUCCESS';
export const SUBSCRIBE_FOR_UPDATE_ERROR = 'SUBSCRIBE_FOR_UPDATE_ERROR';
export const UPDATE_SPOT = 'UPDATE_SPOT';
export const UPDATE_SPOT_SUCCESS = 'UPDATE_SPOT_SUCCESS';
export const UPDATE_SPOT_ERROR = 'UPDATE_SPOT_ERROR';
export const UPDATE_PAGINATION_METADATA = 'UPDATE_PAGINATION_METADATA';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_SPOTS_LIST_METADATA = 'SET_SPOTS_LIST_METADATA';
export const SET_SPOTS_LIST_LOADING = 'SET_SPOTS_LIST_LOADING';
export const SET_OTHER_SPOTS_LIST = 'SET_OTHER_SPOTS_LIST';
export const SET_SPOT_REVIEWS_DATA = 'SET_SPOT_REVIEWS_DATA';

export const getSpotsListBoxMobile = ({ center, bounds, filters, force = false, forceCenter }) => {
    return (dispatch, getState, { apolloClient }) => {
        const state = getState();
        if (!center && !bounds && state.map.doNotLoadSpots) {
            return Promise.resolve();
        } else {
            const requestTs = Date.now();
            dispatch({ type: SET_SPOTS_LIST, requestTs });

            let reqCenter = center || { lat: state.map.center?.lat, lng: state.map.center?.lng };
            let reqBounds = bounds || state.map.bounds;

            if (bounds && bounds.ne.lat !== 0) {
                reqCenter = undefined;
            }

            if (!bounds && state.map.bounds.ne.lat === 0 && state.map.bounds.sw.lat === 0) {
                reqBounds = undefined;
            }

            if (forceCenter) {
                reqCenter = forceCenter;
                reqBounds = undefined;
            }

            return apolloClient
                .query({
                    query: SPOTS_PAGED_WITH_BOX_QUERY,
                    variables: {
                        center: forceCenter ? reqCenter : force ? undefined : reqCenter,
                        bounds: !center ? reqBounds : undefined,
                        filtersNew: filters || getSearchFiltersForServer(state.searchFilters.filters),
                    },
                })
                .then((resp) => {
                    dispatch({ type: SET_SPOTS_LIST_METADATA, value: resp.data.spotsPagedWithBox.spots.metadata });
                    const lastSearch = { center, bounds, filters, force, forceCenter };
                    const spots = resp.data.spotsPagedWithBox.spots.collection;

                    if (resp.data.spotsPagedWithBox.box.center.lat && resp.data.spotsPagedWithBox.box.center.lng) {
                        reqCenter = {
                            lat: resp.data.spotsPagedWithBox.box.center.lat,
                            lng: resp.data.spotsPagedWithBox.box.center.lng,
                        };
                    }

                    dispatch({ type: SET_SEARCH_FILTERS_REFRESH, value: false });
                    dispatch({ type: SET_MAP_DATA, value: { center: reqCenter, lastSearch } });
                    dispatch({ type: SET_SPOTS_LIST_SUCCESS, data: spots, requestTs });

                    if (force) {
                        if (forceCenter) {
                            dispatch({ type: SET_MAP_DATA, value: { isCalcZoom: true, listingSpots: spots } });
                        } else {
                            dispatch({ type: SET_USER_LOCATION, value: { location: reqCenter } });
                        }
                    }
                    return spots;
                })
                .catch((err) => {
                    dispatch({ type: SET_SPOTS_LIST_ERROR, data: err, requestTs });
                    return [];
                });
        }
    };
};

export const getSpotInfo = (spotId) => {
    return (dispatch, getState, { apolloClient }) => {
        dispatch({ type: GET_SPOT_INFO });

        return apolloClient
            .query({
                query: SPOT_INFO_QUERY,
                variables: {
                    id: spotId,
                },
            })
            .then((resp) => {
                dispatch({ type: GET_SPOT_INFO_SUCCESS });
                dispatch({
                    type: SET_SPOT_DATA,
                    data: resp.data.spot,
                });
                return resp;
            })
            .catch((err) => {
                console.error(err);
                dispatch({ type: GET_SPOT_INFO_ERROR });
                dispatch({
                    type: SET_SPOTS_LIST,
                    data: [],
                });
                return {};
            });
    };
};

export const getSpotInfoListing = (spotId) => {
    return (dispatch, getState, { apolloClient }) => {
        dispatch({ type: GET_SPOT_INFO });

        return apolloClient
            .query({
                query: SPOT_INFO_LISTING_QUERY,
                variables: {
                    id: spotId,
                },
            })
            .then((resp) => {
                dispatch({ type: GET_SPOT_INFO_SUCCESS });
                dispatch({
                    type: SET_SPOT_DATA,
                    data: resp.data.spot,
                });
                return resp;
            })
            .catch((err) => {
                console.error(err);
                dispatch({ type: GET_SPOT_INFO_ERROR });
                dispatch({
                    type: SET_SPOTS_LIST,
                    data: [],
                });
                return {};
            });
    };
};

export const getSpotReviewsData = (variables) => {
    return (dispatch, getState, { apolloClient }) => {
        return apolloClient
            .query({ query: SPOT_REVIEWS_DATA, variables })
            .then((resp) => {
                dispatch({ type: SET_SPOT_REVIEWS_DATA, data: resp.data.spot.reviews });
                return resp;
            })
            .catch((err) => {
                console.error(err);
                return {};
            });
    };
};

export const getSpotShortInfo = (spotId) => {
    return (dispatch, getState, { apolloClient }) => {
        dispatch({ type: GET_SPOT_INFO });

        return apolloClient
            .query({
                query: SPOT_INFO_QUERY,
                variables: {
                    id: spotId,
                },
            })
            .then((resp) => {
                dispatch({ type: GET_SPOT_INFO_SUCCESS });
                dispatch({
                    type: SET_SPOT_DATA,
                    data: resp.data.spot,
                });
                return resp;
            })
            .catch((err) => {
                console.error(err);
                dispatch({ type: GET_SPOT_INFO_ERROR });
                dispatch({
                    type: SET_SPOTS_LIST,
                    data: [],
                });
                return {};
            });
    };
};

export const getMoreComments = (dispatch, spotId, skip, sortBy, sortOrder) => {
    dispatch({ type: GET_MORE_COMMENTS });
    new AppolloClient({ uri: `${apiDomain}/graphql` })
        .query({
            query: gql`{
            spot(id: ${spotId}) {
                id
                reviews(skip: ${skip}, first: 5, sortBy: ${sortBy}, sortOrder: ${sortOrder}) {
                    ...Review
                }
            }
        }
        ${REVIEW_FRAGMENT}`,
        })
        .then((resp) => {
            dispatch({ type: GET_MORE_COMMENTS_SUCCESS });
            dispatch({ type: ADD_REVIEWS_TO_LIST, data: resp.data.spot.reviews });
        })
        .catch((err) => {
            dispatch({ type: GET_MORE_COMMENTS_ERROR });
            console.warn(err);
        });
};

export const subscribeForUpdates = (dispatch, email) => {
    dispatch({ type: SUBSCRIBE_FOR_UPDATE });
    new AppolloClient({ uri: `${apiDomain}/graphql` })
        .mutate({
            mutation: gql`
                mutation {
                    subscribe(email: "${email}")
                }`,
        })
        .then((resp) => {
            if (resp.data.subscribe) {
                dispatch({ type: SUBSCRIBE_FOR_UPDATE_SUCCESS });
            } else {
                dispatch({ type: SUBSCRIBE_FOR_UPDATE_ERROR });
            }
        })
        .catch((err) => {
            console.warn('Subscribe error', err);
            dispatch({ type: SUBSCRIBE_FOR_UPDATE_ERROR });
        });
};

export const updateSpot = (spot) => {
    return (dispatch, getState, { apolloClient }) => {
        const stateSpot = getState().spot;

        if (stateSpot === spot) {
            dispatch({ type: UPDATE_SPOT_SUCCESS });
            return;
        }

        const s = { id: stateSpot.id };

        Object.keys(stateSpot).forEach((key) => {
            if (stateSpot[key] !== spot[key]) {
                s[key] = spot[key];
                if (['enclosureType', 'dogsPresent', 'domesticAnimalsPresent', 'peoplePresent'].includes(key)) {
                    s[key] = s[key] == null ? null : s[key].toLowerCase();
                }

                if (key == 'maximumDogsAllowed' || key == 'reservationDelayTime') {
                    s[key] = parseInt(spot[key]);
                }

                if (key == 'photos') {
                    /* eslint-disable no-unused-vars */
                    s.photos = spot.photos.map(({ __typename, imageAws, file, orientation, status, warning, details, ...rest }) => rest);
                    /* eslint-enable no-unused-vars */
                }

                if (['dogsAmenities', 'essentialAmenities', 'peopleAmenities', 'venueAmenities'].includes(key)) {
                    s[key] = spot[key] && spot[key].map((amenities) => amenities.name);
                }
            }
        });

        if (spot.publishType) {
            s.publishType = spot.publishType;
        }

        dispatch({ type: UPDATE_SPOT });

        apolloClient
            .mutate({
                link: new createUploadLink({
                    credentials: COOKIE_MODE,
                    uri: `${apiDomain}/graphql`,
                }),
                variables: s,
                mutation: SPOT_UPDATE,
                update: (proxy, { data: { updateSpot } }) => {
                    const spot = updateSpot.spot;
                    const data = proxy.readQuery({ query: QUERY_USER_DETAILS });
                    const userSpots = data.me.spots.filter((s) => s.id !== spot.id);
                    // eslint-disable-next-line no-undef
                    const newData = structuredClone(data);
                    newData.me.spots = [...userSpots, spot];

                    proxy.writeQuery({ query: QUERY_USER_DETAILS, data: newData });
                    dispatch(setUserDetails(newData.me));
                },
            })
            .then((resp) => {
                dispatch({ type: SET_SPOT_DATA, data: resp.data.updateSpot.spot });
                if (resp.data.updateSpot.errors && resp.data.updateSpot.errors.length) {
                    dispatch({ type: UPDATE_SPOT_ERROR });
                    dispatch({ type: SPOT_GRAPHQL_ERROR, error: resp.data.updateSpot.errors });
                } else {
                    dispatch({ type: UPDATE_SPOT_SUCCESS });
                }
            })
            .catch((error) => {
                console.warn('error', error);
                dispatch({ type: UPDATE_SPOT_ERROR });
            });
    };
};
