import { apolloClient } from '../services/graphql.service';
import { GEOLOCATION_ERRORS } from '../consts/app.consts';
import { REVERSE_GEOCODE_QUERY } from '../graphql/location.queries';
import CookieService from '../services/cookie.service';

export const getUserLocation = () =>
    new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve(position.coords);
            },
            (err) => reject(err),
            { enableHighAccuracy: true, maximumAge: 60 * 1000, timeout: 30000 }
        )
    );

export const fromLatLng = async (lat, lon) =>
    apolloClient
        .query({ query: REVERSE_GEOCODE_QUERY, variables: { lat, lon } })
        .then(({ data, error }) => (!error ? data.geocode.map((item) => item.data) : Promise.reject(error)));

export const findUserLocation = (type, errorCallback, getSpots, first) =>
    getUserLocation()
        .then(({ latitude: lat, longitude: lng }) => {
            const resp = { latLng: { lat, lng } };

            if (type === 'address') {
                const userCookie = JSON.parse(CookieService.get('user'));
                const minLatCookie = parseFloat(userCookie?.location?.lat) - 1;
                const maxLatCookie = parseFloat(userCookie?.location?.lat) + 1;
                const minLngCookie = parseFloat(userCookie?.location?.lng) - 1;
                const maxLngCookie = parseFloat(userCookie?.location?.lng) + 1;

                if (lat >= minLatCookie && lat <= maxLatCookie && lng >= minLngCookie && lng <= maxLngCookie) {
                    return resp;
                }

                return fromLatLng(lat, lng).then((results) => {
                    if (Array.isArray(results)) {
                        const locality = results.find((item) => item.types.includes('locality'));
                        const cityAddr = locality.address_components.find((item) => item.types.includes('locality'));
                        const stateAddr = locality.address_components.find((item) =>
                            item.types.includes('administrative_area_level_1')
                        );

                        if (cityAddr) {
                            resp.city = cityAddr.short_name;
                        }

                        if (stateAddr) {
                            resp.state = stateAddr.short_name;
                        }
                        return resp;
                    } else {
                        return Promise.reject('No reverse geocode results.');
                    }
                });
            } else {
                return resp;
            }
        })
        .catch(async (error) => {
            console.warn(error);

            if (error.code === GEOLOCATION_ERRORS.PERMISSION_DENIED.CODE) {
                first && getSpots && getSpots();
            }

            if (errorCallback) {
                let errorMessage = '';
                if (error.code === GEOLOCATION_ERRORS.PERMISSION_DENIED.CODE) {
                    errorMessage = GEOLOCATION_ERRORS.PERMISSION_DENIED.MESSAGE;
                    first && getSpots && getSpots();
                } else {
                    errorMessage = error.message;
                    getSpots && getSpots();
                }
                errorCallback(true, errorMessage);
                setTimeout(() => errorCallback(false), 5000);
            }
        });
