import { gql } from '@apollo/client';

export const CHANGE_SEARCH_FILTERS = 'CHANGE_SEARCH_FILTERS';
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';
export const SET_SEARCH_FILTERS_COUNT = 'SET_SEARCH_FILTERS_COUNT';
export const SET_SEARCH_FILTERS_REFRESH = 'SET_SEARCH_FILTERS_REFRESH';

export const saveFilters =
    (filters) =>
    (dispatch, _getState, { apolloClient }) => {
        const filtersSpot = JSON.stringify(filters).replace(/"/g, '\\"');

        return apolloClient
            .mutate({
                mutation: gql`
            mutation {
                trackEvent(name: "Search filter" properties:"${filtersSpot}")
            }
        `,
            })
            .catch((err) => {
                console.error(err);
            });
    };

export const setSearchFilters = (value) => (dispatch) => {
    dispatch({ type: CHANGE_SEARCH_FILTERS, value });
};

export const resetSearchFilters = () => (dispatch) => {
    dispatch({ type: RESET_SEARCH_FILTERS });
};

export const setSearchFiltersCount = (value) => (dispatch) => {
    dispatch({ type: SET_SEARCH_FILTERS_COUNT, value });
};

export const setSearchFiltersRefresh = (value) => (dispatch) => {
    dispatch({ type: SET_SEARCH_FILTERS_REFRESH, value });
};
