import { fromLatLng } from './location';

export const getFieldFormAddress = (address, fields) => {
    const attr = ['route'].some((r) => fields.includes(r)) ? 'short_name' : 'long_name';
    const r = address.address_components.find((a) => a.types.some((r) => fields.includes(r)));
    return r === undefined ? null : r[attr];
};

export const locationAddressFromGeocode = async (geocodeResult, latitude, longitude) => {
    const streetName = getFieldFormAddress(geocodeResult, ['route']);
    const streetNumber = getFieldFormAddress(geocodeResult, ['street_number']);
    const street = streetName == null || streetNumber == null ? null : [streetNumber, streetName].join(' ');
    const { default: tz_lookup } = await import('tz-lookup');

    return {
        address: geocodeResult.formatted_address,
        street,
        country: getFieldFormAddress(geocodeResult, ['country']),
        zip: getFieldFormAddress(geocodeResult, ['postal_code']),
        state: getFieldFormAddress(geocodeResult, ['administrative_area_level_1']),
        city: getFieldFormAddress(geocodeResult, ['locality', 'sublocality', 'administrative_area_level_3', 'neighborhood']),
        latitude,
        longitude,
        ...(latitude != null && longitude != null && { timezone: tz_lookup(latitude, longitude) }),
    };
};

export const locationFromLatLng = async (lat, lng) => {
    if (lat & lng) {
        try {
            const [geocode] = await fromLatLng(lat, lng);
            return await locationAddressFromGeocode(geocode, lat, lng);
        } catch (e) {
            console.warn(e);
        }
    }
};
